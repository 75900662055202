
import { Component, Vue } from "vue-property-decorator";
import VueGridLayout from "vue-grid-layout";
import Notify from "@/utils/notifications";
import _ from "underscore";
import Faq from "@/models/Faq";
import System from "@/models/System";
import * as FaqServices from "@/api/helpers/Faq";
import FaqTemplate from "@/components/FaqTemplate.vue";
import FaqDrawer from "@/components/TheFaqDrawer.vue";
import DeletePopover from "@/components/DeletePopover.vue";
import * as SystemServices from "@/api/helpers/System";

@Component({
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    FaqTemplate,
    DeletePopover,
    FaqDrawer,
  },
})
export default class Faqs extends Vue {
  system = System;
  create = false;
  fullscreenLoading = true;
  faqDrawer = false;
  systemId = 1;
  colSize = 3;
  dragWidth = 1;
  dragHeight = 10;
  faq = new Faq();
  activequestions: Array<Faq> = [];
  inactivequestions: Array<Faq> = [];
  activeSystems: Array<System> = [];
  systemName = "";
  layout: Array<object> = [];

  movedEvent(i: number, newX: number, newY: number) {
    const newIndex = this.getNewIndex(newX, newY);
    this.activequestions = _.compact(
      this.arrayMove(this.activequestions, i, newIndex)
    );
    const questionsIds = _.map(this.activequestions, (c: Faq) => {
      return c.id;
    });
    this.updateFaqOrder(questionsIds);
    this.buildGridLayout(this.activequestions);
  }

  getNewIndex(x: number, y: number) {
    if (y > 0) {
      y = Math.ceil(y / this.dragHeight) - 1;
    }
    return x + y * this.colSize;
  }

  arrayMove(array: Array<any>, oldIndex: number, newIndex: number) {
    if (newIndex >= array.length) {
      let k = newIndex - array.length + 1;
      while (k--) {
        array.push(undefined);
      }
    }
    array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
    return array;
  }

  buildGridLayout(array: Array<object>) {
    this.layout = [];
    let row = 0;
    let col = 0;
    _.each(array, (e: object, i: number) => {
      const item = {
        x: col,
        y: row,
        w: this.dragWidth,
        h: this.dragHeight,
        i,
      };
      this.layout.push(item);
      col += 1;
      if (col == this.colSize) {
        col = 0;
        row += this.dragHeight;
      }
    });
  }

  getquestions() {
    const systemId = this.systemId;
    this.fullscreenLoading = true;
    const pickedSystem = _.find(this.activeSystems, (s: System) => {
      return s.id == systemId;
    });
    sessionStorage.setItem("systemId", systemId.toString());
    this.system = pickedSystem;
    if (pickedSystem) this.systemName = pickedSystem.name;
    FaqServices.find({
      order: "order:asc",
      systemId: this.systemId,
    })
      .then(res => {
        const questions = res.data.data;
        const groupedquestions = _.groupBy(questions, (f: Faq) => {
          return f.active;
        });
        this.activequestions = groupedquestions["true"] || [];
        this.inactivequestions = groupedquestions["false"] || [];
        this.buildGridLayout(this.activequestions);
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.fullscreenLoading = false;
      });
  }

  getSystems() {
    SystemServices.find({
      order: "name:asc",
      active: 1,
      showImprovement: 1,
    })
      .then(res => {
        this.activeSystems = res.data.data;
        if (this.activeSystems.length && this.activeSystems[0].id) {
          const systemId = sessionStorage.getItem("systemId");
          this.systemId = systemId
            ? parseInt(systemId)
            : this.activeSystems[0].id;
        }
        this.getquestions();
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  updateFaqOrder(questionsOrder: Array<number | undefined>) {
    this.fullscreenLoading = true;
    FaqServices.order({
      systemId: this.systemId,
      faq: questionsOrder,
    })
      .then(() => {
        Notify.successful("Se modificó el orden de las preguntas.");
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.fullscreenLoading = false;
      });
  }

  deleteFaq(id: number) {
    this.fullscreenLoading = true;
    FaqServices.destroy(id)
      .then(res => {
        this.getSystems();
        Notify.successful(`${res.data.data.name} eliminada exitosamente.`);
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.fullscreenLoading = false;
      });
  }

  chooseFaq(index: number, type: string, active: boolean) {
    this.faq = active
      ? this.activequestions[index]
      : this.inactivequestions[index];
    this.create = false;
    this.faqDrawer = true;
  }

  closeDrawer(value: boolean) {
    this.faqDrawer = value;
  }

  openDrawer() {
    this.faq = new Faq();
    this.create = this.faqDrawer = true;
  }

  created() {
    const index = "8";
    const title = "Faq";
    this.$store.commit("updateCurrentMenuIndex", index);
    this.$store.commit("updateCurrentTitle", title);
    this.$store.commit("updateBreadcrumbItems", [
      {
        text: title,
      },
    ]);
    this.getSystems();
  }
}
